import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';

import '../css/reset.css';
import '../css/base.sass';
import '../css/main.sass';

interface LayoutProps {
  // TODO: Fix this
  children: any;
  page: string;
}

const Layout: React.SFC<LayoutProps> = ({ children, page }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} page={page} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
