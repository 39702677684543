import * as React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import logo from '../images/logo@2x.png';

interface HeaderProps {
  page: string;
  siteTitle: string;
}

const Header: React.SFC<HeaderProps> = ({ page, siteTitle }) => (
  <>
    <Helmet lang="en">
      <title>{siteTitle}</title>
      <html lang="en" />
      <meta
        name="description"
        content="Yellow Dog Real Estate - Rent to own your property"
      />
      <link
        href="https://fonts.googleapis.com/css?family=PT+Serif&display=swap"
        rel="stylesheet"
      />
    </Helmet>

    <header className={`container black ${page}`}>
      <div className="content">
        <Link to="/">
          <img
            src={logo}
            width="126"
            height="100"
            alt="Yellow Dog Real Estate Logo"
          />
        </Link>

        <span className="nav">
          <p>Applications</p>

          <span className="links">
            <span className="investor-information">
              <Link activeClassName="active" to="investor-information">
                Investor Information
              </Link>
            </span>

            <Link activeClassName="active" to="rental-application">
              Rental
            </Link>
            <Link activeClassName="active" to="rent-to-own-application">
              Rent-to-Own
            </Link>
          </span>
        </span>
      </div>
    </header>
  </>
);

export default Header;
