import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

export const Select = ({ name }: { name: string }) => {
  return (
    <select name={name}>
      <option>Yes</option>
      <option>No</option>
    </select>
  );
};

const RentalApplication = () => (
  <Layout page="rental-application">
    <SEO title="Rental Application" />

    <div className="container">
      <div className="content slim">
        <h2>Rental Applications</h2>

        <p>
          Get in touch and we'll get back to you as soon as we can. We look
          forward to hearing from you!
        </p>

        <form
          action="https://formspree.io/casey@yellowdogrealestate.ca"
          method="POST"
        >
          <h3>Personal Information</h3>

          <label className="required">
            Name <input type="text" name="name" required />
          </label>
          <label className="required">
            Phone number <input type="text" name="phone" required />
          </label>
          <label className="required">
            Email <input type="email" name="_replyto" required />
          </label>
          <label>
            Address <input type="text" name="address" />
          </label>
          <label>
            Birthdate <input type="text" name="birthday" />
          </label>
          <h3>Rental History</h3>

          <label>
            Current address <input type="text" name="current-address" />
          </label>
          <label>
            City <input type="text" name="city" />
          </label>
          <label>
            Move-in date <input type="text" name="move-in-date" />
          </label>
          <label>
            Landlords Name <input type="text" name="landlords-name" />
          </label>
          <label>
            Monthly rent <input type="text" name="monthly-rent" />
          </label>
          <label>
            Reason for moving <textarea name="reason-for-moving" />
          </label>

          <h3>Employment Information</h3>

          <label>
            Current employer <input type="text" name="current-employer" />
          </label>
          <label>
            Position <input type="text" name="position" />
          </label>
          <label>
            Employer phone <input type="text" name="employer-phone" />
          </label>
          <label>
            Employer name <input type="text" name="employer-name" />
          </label>
          <label>
            Gross wages per month <input type="text" name="gross-wages" />
          </label>
          <label>
            Hire date <input type="text" name="hire-date" />
          </label>
          <label>
            Other sources on income including amount per month
            <textarea name="other-income" />
          </label>

          <h3>Questionnaire</h3>

          <label>
            How long with you live here? <textarea name="how-long" />
          </label>
          <label>
            What pets do you have? <input type="text" name="pets" />
          </label>
          <label>
            Have you ever had an eviction?
            <Select name="ever-had-eviction" />
          </label>
          <label>
            Have you ever broken a lease?
            <Select name="broken-a-lease" />
          </label>
          <label>
            Do you smoke?
            <Select name="do-you-smoke" />
          </label>
          <label>
            How many vehicles do you own? <input type="text" name="vehicles" />
          </label>
          <label>
            When would you like to move in?
            <input type="text" name="when-move-in" />
          </label>
          <label>
            For what reason could you not pay rent on time?
            <textarea name="no-pay-rent" />
          </label>
          <input type="text" name="_gotcha" className="heyo" />

          <input type="submit" value="Send" />
        </form>
      </div>
    </div>
  </Layout>
);

export default RentalApplication;
