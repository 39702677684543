import React from 'react';
import { Link } from 'gatsby';

const Footer: React.SFC = () => (
  <>
    <div className="container footer">
      <div className="content">
        <p>Yellow Dog Real Estate</p>

        <span className="links">
          <a href="mailto:casey@yellowdogrealestate.ca">Email Us</a>
          <Link to="/investor-information">Investor Information</Link>
          <Link to="/rental-application">Rental Application</Link>
          <Link to="/rent-to-own-application">Rent-to-Own Application</Link>
        </span>
      </div>
    </div>
  </>
);

export default Footer;
